import React, { useState } from 'react';
import "../../Css/RequestModal.css";
import axiosInstance from '../../axiousInstance';

const RequestTestimonialModal = ({ isOpen, onClose, onRequestTestimonial, storyId, authorName, storyTitle }) => {
  const [emailAddresses, setEmailAddresses] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const validateEmails = (emails) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emails.every((email) => emailPattern.test(email));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emails = emailAddresses.split(',').map((email) => email.trim());
  
    if (emails.length === 0) {
      setError('Please enter at least one email address.');
      return;
    }
  
    if (!validateEmails(emails)) {
      setError('Please enter valid email addresses.');
      return;
    }
  
    setError('');
  
    try {
      const response = await axiosInstance.post(
        `/testimonial/request-testimonial`,
        { emails, message, storyId, authorName, storyTitle },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      
      if (response.status === 200) {
        console.log('Testimonial request sent successfully', response.data);
        onClose();  // Close the modal
        setEmailAddresses(''); // Clear inputs
        setMessage('');
      } else {
        setError(response.data.message || 'Something went wrong!');
      }
    } catch (error) {
      setError('Failed to send testimonial request.');
    }
  };
  
  // Close the modal when clicking outside the modal content
  const handleClickOutside = (e) => {
    if (e.target.className === 'modal') {
      onClose();
    }
  };

  // Conditionally render the modal only when `isOpen` is true
  if (!isOpen) return null;

  return (
    <div className="modal" onClick={handleClickOutside}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Request Testimonial</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="emailAddresses">Enter Email Addresses (comma-separated):</label>
            <input
              type="text"
              id="emailAddresses"
              value={emailAddresses}
              onChange={(e) => setEmailAddresses(e.target.value)}
              placeholder="e.g., client1@example.com, client2@example.com"
              required
            />
            {error && <p className="error-message">{error}</p>}
          </div>

          <div className="form-group">
            <label htmlFor="message">Message (optional):</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Include any additional message to the clients..."
            />
          </div>

          <div className="form-actions">
            <button type="submit" className="submit-btn" style={{marginRight: '10px'}}>
              Send Request
            </button>
            <button type="button" className="cancel-btn" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RequestTestimonialModal;
