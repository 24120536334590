import { Link } from "react-router-dom";
import '../../Css/Header.css'

const Header2 = () => { 
    return (

        <header>
            <div className="averager">
                <Link to="/" className="logo">
                    <img src='/logo.svg' alt="logo" width={150} height={150}/>
                </Link>
            </div>

        </header>

    )
}

export default Header2;
