import React from 'react';
import '../../Css/Footer.css'

const Footer = () => {
    return (
        <div>
            <div className="footer">
            </div>
            <div className="copyright">
                <p className="copyright-blog">© 2024 <a href="https://www.shayansolutions.com" target="_blank" rel="noopener noreferrer" className="footer-link">Shayan Solutions</a>. All Rights Reserved</p>
            </div>
        </div>
    )
}

export default Footer;
