import React from 'react';
import '../../Css/Pagination.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { TiMinus } from 'react-icons/ti';

const Pagination = ({ page, pages, changePage }) => {
    function numberRange(start, end) {
        return new Array(Math.max(end - start, 0)) // Ensure non-negative array length
            .fill()
            .map((d, i) => i + start);
    }

    let middlePagination;

    if (pages <= 5) {
        middlePagination = [...Array(pages)].map((__, index) => (
            <button
                key={index + 1}
                onClick={() => changePage(index + 1)}
                disabled={page === index + 1}
            >
                {index + 1}
            </button>
        ));
    } else {
        const startValue = Math.floor((page - 1) / 5) * 5;
        middlePagination = (
            <>
                {numberRange(startValue + 1, Math.min(startValue + 5, pages)).map((pageNumber) => (
                    <button
                        key={pageNumber}
                        onClick={() => changePage(pageNumber)}
                        disabled={page === pageNumber}
                    >
                        {pageNumber}
                    </button>
                ))}
                {pages > startValue + 5 && <button>...</button>}
                <button onClick={() => changePage(pages)} disabled={page === pages}>
                    {pages}
                </button>
            </>
        );

        if (page > 5) {
            middlePagination = (
                <>
                    <button onClick={() => changePage(1)}>1</button>
                    <button>...</button>
                    {numberRange(startValue + 1, Math.min(startValue + 5, pages)).map((pageNumber) => (
                        <button
                            key={pageNumber}
                            onClick={() => changePage(pageNumber)}
                            disabled={page === pageNumber}
                        >
                            {pageNumber}
                        </button>
                    ))}
                    {pages > startValue + 5 && <button>...</button>}
                    <button onClick={() => changePage(pages)} disabled={page === pages}>
                        {pages}
                    </button>
                </>
            );
        }
    }

    return (
        pages > 1 && (
            <div className="pagination">
                <button
                    className="pagination__prev"
                    onClick={() => changePage(page - 1)}
                    disabled={page === 1}
                >
                    {page === 1 ? <TiMinus color="gray" /> : <FaChevronLeft />}
                </button>
                {middlePagination}
                <button
                    className="pagination__next"
                    onClick={() => changePage(page + 1)}
                    disabled={page === pages}
                >
                    {page === pages ? <TiMinus color="gray" /> : <FaChevronRight />}
                </button>
            </div>
        )
    );
};

export default Pagination;
