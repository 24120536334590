import React, { useState, useEffect, useContext } from "react";
import { FaUserAlt } from "react-icons/fa";
import { AiOutlineUpload } from "react-icons/ai";
import Loader from "../GeneralScreens/Loader";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import "../../Css/EditProfile.css";
import axiosInstance from "../../axiousInstance";

const EditProfile = () => {
  const { activeUser, config } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [businessDetails, setBusinessDetails] = useState("");
  const [services, setServices] = useState("");
  const [aboutUs, setAboutUs] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [photo, setPhoto] = useState("");
  const [previousPhoto, setPreviousPhoto] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formdata = new FormData();
  
    // Only append the fields that have new or changed data
    if (username) formdata.append("username", username);
    if (email) formdata.append("email", email);
    
    // Append photo only if the user has selected a new one
    if (photo !== previousPhoto && photo) {
      formdata.append("photo", photo);
    }
  
    if (businessDetails) formdata.append("businessDetails", businessDetails);
    if (services) formdata.append("services", services);
    if (aboutUs) formdata.append("aboutUs", aboutUs);
    if (websiteLink) formdata.append("websiteLink", websiteLink);
  
    try {
      const { data } = await axiosInstance.post("/user/editProfile", formdata, config);
  
      setSuccess("Edit Profile successfully");
      setTimeout(() => {
        navigate("/profile");
      }, 1500);
    } catch (error) {
      setTimeout(() => {
        setError("");
      }, 7000);
      setError(error.response.data.error);
    }
  };
  useEffect(() => {
    setUsername(activeUser.username);
    setEmail(activeUser.email);
    setPreviousPhoto(activeUser.photo);
    setPhoto(activeUser.photo);
    setBusinessDetails(activeUser.businessDetails);
    setServices(activeUser.services);
    setAboutUs(activeUser.aboutUs);
    setWebsiteLink(activeUser.websiteLink);
    setTimeout(() => {
      setLoading(false);
    }, 1050);
  }, [navigate, activeUser]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="Inclusive-editprofile-page">
          <form onSubmit={handleSubmit}>
            {error && <div className="error_msg">{error}</div>}

            {success && <div className="success_msg">{success} </div>}

            <div className="input-wrapper">
              <input
                type="text"
                id="username"
                placeholder="Username  "
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label htmlFor="username">Username</label>
            </div>

            <div className="input-wrapper">
              <input
                type="email"
                id="email"
                placeholder="Email  "
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="email">E-mail</label>
            </div>
            <div className="input-wrapper">
              <textarea
                id="businessDetails"
                placeholder="Business Details"
                name="businessDetails"
                value={businessDetails}
                onChange={(e) => setBusinessDetails(e.target.value)}
              />
              <label htmlFor="businessDetails">Business Details</label>
            </div>

            <div className="input-wrapper">
              <textarea
                id="services"
                placeholder="Services"
                name="services"
                value={services}
                onChange={(e) => setServices(e.target.value)}
              />
              <label htmlFor="services">Services</label>
            </div>

            <div className="input-wrapper">
              <textarea
                id="aboutUs"
                placeholder="About Us"
                name="aboutUs"
                value={aboutUs}
                onChange={(e) => setAboutUs(e.target.value)}
              />
              <label htmlFor="about">About Us</label>
            </div>

            <div className="input-wrapper">
              <input
                type="text"
                id="websiteLink"
                placeholder="Website Link   "
                name="websiteLink"
                value={websiteLink}
                onChange={(e) => setWebsiteLink(e.target.value)}
              />
              <label htmlFor="websiteLink">Website Link</label>
            </div>

            <div className="profile-ımg-upld-wrapper">
              <div class="ProfilePhotoField">
                <FaUserAlt />
                <div class="txt">
                  {photo === previousPhoto ? (
                    <div>
                      <AiOutlineUpload />
                      <span>Change Profile Photo</span>
                    </div>
                  ) : (
                    photo.name
                  )}
                </div>
                <input
                  name="photo"
                  type="file"
                  onChange={(e) => {
                    setPhoto(e.target.files[0]);
                  }}
                />
              </div>

              <div class="currentImage">
                <div class="absolute">Currently Image</div>
                <img
                  src={photo}
                  alt="userPhoto"
                />
              </div>
            </div>

            <button type="submit" className="editprofile-btn">
              Edit Profile
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default EditProfile;
