import React from 'react';
import TestimonialForm from "../../components/TestimonialForm";
import "../../Css/ReceiveTestimonialPage.css"; // Import the CSS file
import Header from '../GeneralScreens/Header2';

const ReceiveTestimonialPage = () => {
    return (
      <>
        <Header />
        <div className="receive-testimonial-page">
          <h1>Leave a Testimonial</h1>
          <TestimonialForm />
        </div>
      </>
    );
};

export default ReceiveTestimonialPage;
