import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import "../../Css/DetailStory.css";
import Loader from "../GeneralScreens/Loader";
import { FaRegHeart, FaHeart, FaCheck } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit, FiArrowLeft } from "react-icons/fi";
import { FaRegComment } from "react-icons/fa";
import { BsBookmarkPlus, BsThreeDots, BsBookmarkFill } from "react-icons/bs";
import CommentSidebar from "../CommentScreens/CommentSidebar";
import axiosInstance from "../../axiousInstance";
import StarRatingComponent from "react-rating-stars-component";
import RequestTestimonialModal from "../../components/modals/requestTestimonialModal";
import testimonials from './data.json'

const DetailStory = () => {
  const [likeStatus, setLikeStatus] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [activeUser, setActiveUser] = useState({});
  const [story, setStory] = useState({});
  const [storyLikeUser, setStoryLikeUser] = useState([]);
  const [testimonials, setTestimonials] = useState([]); 
  const [sidebarShowStatus, setSidebarShowStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const slug = useParams().slug;
  const [storyReadListStatus, setStoryReadListStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  let projectId = story._id;

  useEffect(() => {
    const getDetailStory = async () => {
      setLoading(true);
      let activeUser = {};
      try {
        const { data } = await axiosInstance.get("/auth/private", {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        activeUser = data.user;
        setActiveUser(activeUser);
      } catch (error) {
        setActiveUser({});
      }

      try {
        const { data } = await axiosInstance.post(`/story/${slug}`, {
          activeUser,
        });
        setStory(data.data);
        setLikeStatus(data.likeStatus);
        setLikeCount(data.data.likeCount);
        setStoryLikeUser(data.data.likes);
        setLoading(false);

        const story_id = data.data._id;
        if (activeUser.readList && activeUser.readList.includes(story_id)) {
          setStoryReadListStatus(true);
        } else {
          setStoryReadListStatus(false);
        }
      } catch (error) {
        setStory({});
        navigate("/not-found");
      }
    };
    getDetailStory();
  }, [slug, navigate]);

  const handleLike = async () => {
    setTimeout(() => {
      setLikeStatus(!likeStatus);
    }, 1500);

    try {
      const { data } = await axiosInstance.post(
        `/story/${slug}/like`,
        { activeUser },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setLikeCount(data.data.likeCount);
      setStoryLikeUser(data.data.likes);
    } catch (error) {
      setStory({});
      localStorage.removeItem("authToken");
      navigate("/");
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Do you want to delete this post")) {
      try {
        await axiosInstance.delete(`/story/${slug}/delete`, {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        navigate("/");
      } catch (error) {
        console.log(error);
      }
    }
  };
  const editDate = (createdAt) => {
    const d = new Date(createdAt);
    const datestring =
      d.toLocaleString("en", { month: "short" }) + " " + d.getDate();
    return datestring;
  };

  const addStoryToReadList = async () => {
    try {
      const { data } = await axiosInstance.post(
        `/user/${slug}/addStoryToReadList`,
        { activeUser },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setStoryReadListStatus(data.status);
      document.getElementById("readListLength").textContent =
        data.user.readListLength;
    } catch (error) {
      console.log(error);
    }
  };

  const handleRequestTestimonial = (data) => {
    console.log("Testimonial request data:", data);
    setIsModalOpen(false);
  };

  const handleVerifyTestimonial = async (testimonialId, verifiedStatus) => {
    console.log(testimonialId, verifiedStatus);
    try {
      // Send the request to update the testimonial verification status
      await axiosInstance.put(`/testimonial/updateTestimonial/${testimonialId}`, {
        verified: verifiedStatus,
      });
  
      // Update the local state by finding and updating the testimonial directly
      setTestimonials((prevTestimonials) =>
        prevTestimonials.map((testimonial) =>
          testimonial._id === testimonialId
            ? { ...testimonial, verified: verifiedStatus }
            : testimonial
        )
      );
    } catch (error) {
      console.log("Error verifying testimonial:", error);
    }
  };

  useEffect(() => {
    getProjectTestimonials()
  }, [slug])


  const getProjectTestimonials = async () => {
    try {
      const { data } = await axiosInstance.get(`/testimonial/${slug}/getAllTestimonial`)
      console.log(data);
      setTestimonials(data.data)
    }
    catch (error) {
      console.log(error.response.data.error);
    }
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="Inclusive-detailStory-page">
          <div className="top_detail_wrapper">
            <Link to={"/"}>
              <FiArrowLeft />
            </Link>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h5>{story.title}</h5>
              {activeUser._id === story.author?._id && (
                <button
                  style={{
                    backgroundColor: "#0063a5",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "16px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s ease",
                  }}
                  onClick={() => setIsModalOpen(true)}
                >
                  Request Testimonial
                </button>
              )}
            </div>

            <div className="story-general-info">
              <ul>
                {story.author && (
                  <li className="story-author-info">
                    <img src={story.author.photo} alt={story.author.username} />
                    <span className="story-author-username">
                      {story.author.username}
                    </span>
                  </li>
                )}
                <li className="story-createdAt">{editDate(story.createdAt)}</li>
                <b>-</b>
                <li className="story-readtime">{story.readtime} min read</li>
              </ul>

              {!activeUser.username && (
                <div className="comment-info-wrap">
                  <i
                    onClick={() => {
                      setSidebarShowStatus(!sidebarShowStatus);
                    }}
                  >
                    <FaRegComment />
                  </i>
                  <b className="commentCount">{story.commentCount}</b>
                </div>
              )}

              {activeUser &&
              story.author &&
              story.author._id === activeUser._id ? (
                <div className="top_story_transactions">
                  <Link
                    className="editStoryLink"
                    to={`/story/${story.slug}/edit`}
                  >
                    <FiEdit />
                  </Link>
                  <span className="deleteStoryLink" onClick={handleDelete}>
                    <RiDeleteBin6Line />
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          <div className="CommentFieldEmp">
            <CommentSidebar
              slug={slug}
              sidebarShowStatus={sidebarShowStatus}
              setSidebarShowStatus={setSidebarShowStatus}
              activeUser={activeUser}
            />
          </div>

          <div className="story-content">
            <div className="story-banner-img">
              <img src={story.image} alt={story.title} />
            </div>
            <span style={{ fontWeight: "bold", color: "#000", fontSize: "20px" }}>Project Description:</span>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: story.content }}
            ></div>
          </div>
          <div className="story-content">
            <span style={{ fontWeight: "bold", color: "#000", fontSize: "20px" }}>Challenges Faced And Solutions:</span>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: story.challengesFaced }}
            ></div>
          </div>
          <div className="story-content">
          <span style={{ fontWeight: "bold", color: "#000", fontSize: "20px"}}>Outcomes or Benefits:</span>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: story.outcomes }}
            ></div>
          </div>

          {/* Verified Testimonials Section */}
          <div className="testimonial-section">
            <h3>Testimonials</h3>
            <div className="testimonial-cards">
              {testimonials.length > 0 ? (
                testimonials.map(
                  (testimonial) =>
                    (testimonial.verified ||
                      activeUser._id === story.author._id) && (
                      <div key={testimonial._id} className="testimonial-card">
                        <div className="testimonial-header">
                          <img
                            src={testimonial.profilePhoto}
                            alt={`${testimonial.clientName}'s profile`}
                            className="testimonial-profile-photo"
                          />
                          <div className="testimonial-client-info">
                            <h4>{testimonial.clientName}</h4>
                            {testimonial.verified && (
                              <span className="verified-badge">
                                <FaCheck />
                              </span>
                            )}
                          </div>
                        </div>
                        <p className="testimonial-message">
                          {testimonial.testimonialMessage}
                        </p>
                        <StarRatingComponent
                          value={testimonial.rating}
                          count={5}
                          size={24}
                          activeColor="#04599C"
                        />
                        {activeUser._id === story.author._id && (
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                checked={testimonial.verified}
                                onChange={() =>
                                  handleVerifyTestimonial(
                                    testimonial._id,
                                    !testimonial.verified
                                  )
                                }
                              />
                              Verified
                            </label>
                          </div>
                        )}
                      </div>
                    )
                )
              ) : (
                <p>No Testimonial yet.</p>
              )}
            </div>
          </div>
          {activeUser.username && (
            <div className="fixed-story-options">
              <ul>
                <li>
                  <i onClick={handleLike}>
                    {likeStatus ? <FaHeart color="#0063a5" /> : <FaRegHeart />}
                  </i>
                  <b
                    className="likecount"
                    style={
                      likeStatus
                        ? { color: "#0063a5" }
                        : { color: "rgb(99, 99, 99)" }
                    }
                  >
                    {likeCount}
                  </b>
                </li>

                <li>
                  <i onClick={() => setSidebarShowStatus(!sidebarShowStatus)}>
                    <FaRegComment />
                  </i>
                  <b className="commentCount">{story.commentCount}</b>
                </li>
              </ul>

              <ul>
                <li>
                  <i onClick={addStoryToReadList}>
                    {storyReadListStatus ? (
                      <BsBookmarkFill color="#0205b1" />
                    ) : (
                      <BsBookmarkPlus />
                    )}
                  </i>
                </li>

                <li className="BsThreeDots_opt">
                  <i>
                    <BsThreeDots />
                  </i>

                  {activeUser && story.author._id === activeUser._id ? (
                    <div className="delete_or_edit_story">
                      <Link
                        className="editStoryLink"
                        to={`/story/${story.slug}/edit`}
                      >
                        <p>Edit Project</p>
                      </Link>
                      <p className="deleteStoryLink" onClick={handleDelete}>
                        Delete Project
                      </p>
                    </div>
                  ) : null}
                </li>
              </ul>
            </div>
          )}

          <RequestTestimonialModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onRequest={handleRequestTestimonial}
            storyId={story._id}
            authorName={activeUser.username}
            storyTitle={story.title}
          />
        </div>
      )}
    </>
  );
};

export default DetailStory;
