import React, { useEffect, useState, useRef, useContext } from 'react';
import Loader from '../GeneralScreens/Loader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { AuthContext } from "../../Context/AuthContext";
import { AiOutlineUpload } from 'react-icons/ai'
import '../../Css/EditStory.css'
import axiosInstance from '../../axiousInstance';

const EditStory = () => {
    const { config } = useContext(AuthContext)
    const slug = useParams().slug
    const imageEl = useRef(null)
    const [loading, setLoading] = useState(true)
    const [story, setStory] = useState({})
    const [image, setImage] = useState('')
    const [previousImage, setPreviousImage] = useState('')
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [challengesFaced, setChallengesFaced] = useState('')
    const [outcomes, setOutcomes] = useState('')
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')
    const navigate = useNavigate()

    useEffect(() => {

        const getStoryInfo = async () => {
            setLoading(true)
            try {
                const { data } = await axiosInstance.get(`/story/editStory/${slug}`, config)
                setStory(data.data)
                setTitle(data.data.title)
                setContent(data.data.content)
                setChallengesFaced(data.data.challengesFaced)
                setOutcomes(data.data.outcomes)
                setImage(data.data.image)
                setPreviousImage(data.data.image)
                setLoading(false)
            } catch (error) {
                navigate("/")
            }
        }
        getStoryInfo()
    }, [config, slug, navigate])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formdata = new FormData()
        formdata.append("title", title)
        formdata.append("content", content)
        formdata.append("challengesFaced", challengesFaced)
        formdata.append("outcomes", outcomes)

        // Only append image if it's been changed
        if (image !== previousImage) {
            formdata.append("image", image)
        }

        formdata.append("previousImage", previousImage)

        try {
            const { data } = await axiosInstance.put(`/story/${slug}/edit`, formdata, config)
            setSuccess('Edit Story successfully ')

            setTimeout(() => {
                navigate('/')
            }, 2500)

        } catch (error) {
            setTimeout(() => {
                setError('')
            }, 4500)
            setError(error.response?.data?.error || "An error occurred.")
        }
    }

    return (
        <>
            {loading ? <Loader /> : (
                <div className="Inclusive-editStory-page">
                    <form onSubmit={handleSubmit} className="editStory-form">

                        {error && <div className="error_msg">{error}</div>}
                        {success && <div className="success_msg">
                            <span>{success}</span>
                            <Link to="/">Go home</Link>
                        </div>}

                        <input
                            type="text"
                            required
                            id="title"
                            placeholder="Title"
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                        />

                        <div className="editor-wrapper">
                            <label>Project Description</label>
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(e, editor) => {
                                    const data = editor.getData();
                                    setContent(data)
                                }}
                                data={content}
                            />
                        </div>

                        <div className="editor-wrapper">
                            <label>Challenges Faced and Solutions</label>
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(e, editor) => {
                                    const data = editor.getData();
                                    setChallengesFaced(data)
                                }}
                                data={challengesFaced}
                            />
                        </div>

                        <div className="editor-wrapper">
                            <label>Outcomes or Benefits</label>
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(e, editor) => {
                                    const data = editor.getData();
                                    setOutcomes(data)
                                }}
                                data={outcomes}
                            />
                        </div>

                        <div className="currentlyImage">
                            <div className="absolute">Currently Image</div>
                            <img src={previousImage} alt="storyImage" />
                        </div>

                        <div className="StoryImageField">
                            <AiOutlineUpload />
                            <div className="txt">
                                {image === previousImage ? "Change the image in your project" : image.name}
                            </div>
                            <input
                                name="image"
                                type="file"
                                ref={imageEl}
                                onChange={(e) => setImage(e.target.files[0])}
                            />
                        </div>

                        <button type='submit' className='editStory-btn'>Edit Story</button>
                    </form>
                </div>
            )}
        </>
    )
}

export default EditStory;
