import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom'; // To get URL params
import "../Css/TestimonialForm.css";
import StarRatingComponent from 'react-rating-stars-component';
import axiosInstance from '../axiousInstance';

const TestimonialForm = () => {
  const [clientName, setClientName] = useState('');
  const [testimonialMessage, setTestimonialMessage] = useState('');
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [rating, setRating] = useState(0);
  const [error, setError] = useState('');
  const [designation, setDesignation] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  const [searchParams] = useSearchParams();
  const slug = searchParams.get('projectId'); // Get slug/projectId from the URL
  const authorName = searchParams.get('projectDeveloper');
  const storyTitle = searchParams.get('projectName');

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePhoto(file);
    }
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!clientName || !testimonialMessage || rating === 0) {
      setError('Please fill in all required fields.');
      return;
    }

    setError('');

    const formData = new FormData();
    formData.append('clientName', clientName);
    formData.append('testimonialMessage', testimonialMessage);
    formData.append('rating', rating);
    formData.append('designation', designation);
    formData.append('linkedin', linkedin);
    formData.append('email', email);
    if (profilePhoto) {
      formData.append('profilePhoto', profilePhoto);
    }

    try {
      const response = await axiosInstance.post(
        `/testimonial/${slug}/addTestimonial`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        setSuccessMessage('Your testimonial has been submitted for verification.');
        setClientName('');
        setTestimonialMessage('');
        setDesignation('');
        setLinkedin('');
        setEmail('');
        setProfilePhoto(null);
        setRating(0);
        setProfilePhoto(null);
      } else {
        setError('There was an issue submitting your testimonial. Please try again.');
      }
    } catch (error) {
      setError('There was an error submitting your testimonial. Please check your network connection.');
    }
  };

  return (
    <div className="testimonial-form-container">
      <div className="project-details">
        <p className="project-name">
          <strong>Project:</strong> {storyTitle}
        </p>
        <p className="project-author">
          <strong>Developer:</strong> {authorName}
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="clientName">Your Name:</label>
          <input
            type="text"
            id="clientName"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientName">Your Email:</label>
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientName">Your Designation:</label>
          <input
            type="text"
            id="designation"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientName">Your Linkedin Profile:</label>
          <input
            type="text"
            id="linkedin"
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="testimonialMessage">Your Testimonial:</label>
          <textarea
            id="testimonialMessage"
            value={testimonialMessage}
            onChange={(e) => setTestimonialMessage(e.target.value)}
            required
          />
        </div>

        {/* Star Rating Component */}
        <div className="form-group">
          <label htmlFor="rating">Your Rating:</label>
          <StarRatingComponent
            id="rating"
            count={5} // Set the max stars to 5
            value={rating}
            onChange={handleRatingChange}
            size={30}
            activeColor="#04599C" // Gold color for the stars
          />
        </div>

        <div className="form-group">
          <label htmlFor="profilePhoto">Profile Photo (optional):</label>
          <input
            type="file"
            id="profilePhoto"
            accept="image/*"
            onChange={handlePhotoChange}
          />
        </div>

        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <div className="form-actions">
          <button type="submit" className="submit-btn">
            Submit Testimonial
          </button>
        </div>
      </form>
    </div>
  );
};

export default TestimonialForm;
